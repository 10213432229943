/**
 * Color class
 *
 * Class for 360
 */
window.Colors = new (function () {

    var that = this;
    var car360 = '';

    /**
     * Init
     */
    this.init = function () {

        //init the first color
        that.initView360();

        //change color
        $(document).on('click', '.exteriorColor', that.changeColor);

        //$('.ico-360').on('click', that.changeFrame);

    }; //init

    this.initBuild = function () {

        //change color
        $(document).on('click', '.exteriorColor', that.changeCarColor);

        that.changeCar();

    }; //init

    /**
     * Trigger to first color
     */
    this.firstColorTrigger = function () {
        $('.exteriorColorItems .exteriorColor').first().trigger('click');
    };

    /**
     * initView360
     */
    this.initView360 = function () {

        //hide the sample image
        $('.initImg').addClass('d-none');

        car360 = $('.threesixty').ThreeSixty({
            totalFrames: 3, // Total no. of image you have for 360 slider
            endFrame: 3, // end frame for the auto spin animation
            currentFrame: 1, // This the start frame for auto spin
            imgList: '.threesixty_images', // selector for image list
            progress: '.spinner', // selector to show the loading progress
            imagePath: imgFolder, // path of the image assets
            filePrefix: '', // file prefix if any
            ext: '.png' + imgVersion, // extention for the assets
            navigation: false,
            responsive: true,
            framerate: 3,
            disableSpin: true,
            onReady: function () {
                var imgHeight = $('.threesixty ol li img').height();
                $('.threesixty').attr('style', 'height: ' + imgHeight + 'px; min-height: ' + imgHeight + 'px; max-height: ' + imgHeight + 'px');
                car360.next();

                setTimeout( function () {
                    //that.changeFrame();
                    car360.previous();

                }, 1000);
            }
        });


    }; //initView360


    /**
     * Init the carousel
     * for exterior colors
     */
    this.initExteriorCarousel = function () {

        var totalColors = $('.exteriorColorItems .item').length;

        $('.exteriorColorItems').slick({
            infinite: false,
            slidesToShow: totalColors,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '10px',
                }
            },
                {
                    breakpoint: 1023,
                    settings: {
                        arrows: true,
                        centerMode: false,
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },


            ]
        });
    }; //initExteriorCarousel


    /**
     * Change 360 color
     */
    this.changeColor = function () {

        //change the color exterior indicator
        that.changeColorSelectedIndicator($(this));

        //change the current color title
        that.changeTextColorSelected($(this));

        //Change the 360 folder
        imgFolder = $(this).parent().data('folder');
        $('.threesixty_images').html('');
        that.initView360();

    }; //changeColor
    /**
     * Change color
     */
    this.changeCarColor = function () {

        //change the color exterior indicator
        that.changeColorSelectedIndicator($(this));

        //change the current color title
        that.changeTextColorSelected($(this));

        //Change the 360 folder
        imgFolder = $(this).parent().data('folder');
        that.changeCar();

    }; //changeColor
    /**
     * change car
     */
    this.changeCar = function () {
        //$('.CarColor .CarVersion-item img').attr('src', imgFolder + '1.png');
    }
    /**
     * Change the color indicator
     * @param element (the button)
     */
    this.changeColorSelectedIndicator = function (element) {

        $('.exteriorColor').parent().removeClass('active');
        $(element).parent().addClass('active');

    }; //changeColorSelectedIndicator

    /**
     * Change the text color selected
     * @param element (the button)
     */
    this.changeTextColorSelected = function (element) {

        //get data
        var colorExtName = $(element).parent().data('extname');
        var colorIntName = $(element).parent().data('intname');
        var colorIntHexa = $(element).parent().data('inthexa');

        //Exterior name
        $('.exteriorName').html(colorExtName);

        //empty color interior wrap
        $('.colorInteriorWrap .wrap-int-color').remove();

        //build interior wrap
        var interiorColor = "";

        $.each(colorIntName.split(","), function (index, value) {

            //ignore empty value
            if(value != '') {
                //build elements interior
                interiorColor += '<div class="wrap-int-color">';


                var colorIntHexaSplit = colorIntHexa.toString().split(",");
                var hex = $.trim(colorIntHexaSplit[index]);
                hex = String(hex);
                hex = hex.split("/");
                //combine colors
                if(typeof hex[1] != 'undefined') {
                    var otherHex = hex[1].split(",");
                    interiorColor += '<div class="colorInt half left" data-name="'+ value +'" style="background-color: #'+ hex[0].replace(',', '') +'"></div>';
                    interiorColor += '<div class="colorInt half right" data-name="'+ value +'" style="background-color: #'+ otherHex[0].replace(',', '') +'"></div>';
                } else {
                    //just one color
                    colorIntHexa = colorIntHexa + '';
                    interiorColor += '<div class="colorInt" data-name="'+ value +'" style="background-color: #'+ colorIntHexa.split(",")[index] +'"></div>';
                }

                //color interior name
                interiorColor += '<label class="colorIntName">'+ value +'</label>';
                interiorColor += '</div>';
            }

        });


        $('.colorInteriorWrap').append(interiorColor);

    }; //changeTextColorSelected

    /**
     * Remove Attr style
     */
    this.removeHeight = function () {
        $('.threesixty').attr('style', '');
    };

    /**
     * Change 360 frame
     */
    this.changeFrame = function () {
        car360.next();
    };

    this.getInteriorColor = function () {

    };

})();
