/**
 * Car Main class
 *
 * Class for all sections in car
 */
window.Car = new(function () {

    const Swal = require('sweetalert2');

    var that = this;

    /**
     * Init
     */
    this.init = function () {
        //open mini drop to choose year
        $('.DropHandler').on('click', function () {
            $(this).parent().toggleClass('openDrop');
            $(this).toggleClass('showYear');

            if ($('.VehicleOwnMenu-active').hasClass('openMenuMb')) {
                $('.VehicleOwnMenu-active').toggleClass('openMenuMb');
                $('.VehicleOwnMenu-links').toggleClass('openMenuMb');
            }
        });

        //open vehicles menu on mobile
        $('.VehicleOwnMenu-active').on('click', function () {
            $(this).toggleClass('openMenuMb');
            $('.VehicleOwnMenu-links').toggleClass('openMenuMb');

            if ($('.DropHandler').hasClass('showYear')) {
                $('.DropHandler').parent().toggleClass('openDrop');
                $('.DropHandler').toggleClass('showYear');
            }
        });

        $('.VehicleOwnMenu-active span').text($('.VehicleOwnMenu-links .active').text());

        //add class active in tap to element in mobile
        $('.VehicleOwnMenu-links li a').on('click', that.addRemoveActiveCarSectionsMenu);

        //Quotation form
        $('.openGuatemalaQuoteForm').on('click', that.openGuatemalaQuoteForm);

        $('.description').on('click', that.showDisclaimer);

        $(document).on('click', '.close-disclaimer', that.removeDisclaimer);

    }; //init

    /**
     * Add Remove Active
     * Car Sections menu
     */
    this.addRemoveActiveCarSectionsMenu = function () {
        $(this).addClass('active');
    };

    /**
     * Quotation Form
     * Guatemala
     */
    this.openGuatemalaQuoteForm = function () {

        Swal.fire({
            icon: 'info',
            html: htmlFormGuatemala,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'guatemala-popup'
        });

    };

    /**
     * Send quotation info
     * Guatemala
     */
    this.sendGuatemalaQuotationForm = function() {

        var inputFields = $('#quotation-guatemala-form input, #quotation-guatemala-form select');
        var validationPass = true;


        $.each(inputFields, function(index, input) {
            $(input).removeClass('inputError');
            $('#quotation-guatemala-form .error').addClass('d-none');

            if($(input).val() == '') {
                validationPass = false;
                $(input).addClass('inputError');
            }

            if($(input).attr('type') == 'email' && !Main.isValidEmailAddress($(input).val())) {
                validationPass = false;
                $(input).addClass('inputError');
            }

            if( !validationPass ) {
                $('#quotation-guatemala-form .error').removeClass('d-none');
            }

        });


        if( validationPass ) {

            var fname = $(document).find('form#quotation-guatemala-form input#name').val();
            var flname = $(document).find('form#quotation-guatemala-form input#last_name').val();
            var femail = $(document).find('form#quotation-guatemala-form input#email').val();
            var fphone = $(document).find('form#quotation-guatemala-form input#phone').val();
            var filine = $(document).find('form#quotation-guatemala-form select#interest-line').val();

            //show wait
            Swal.fire({
                title: 'Procesando',
                text: 'Por favor, espere mientras sus datos son enviados',
                showCloseButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            //send data
            $.ajax({
                type: 'POST',
                url: sendQuotationGuatemala,
                data: {
                    '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                    'name': fname,
                    'last_name': flname,
                    'email': femail,
                    'phone': fphone,
                    'interest-line': filine,
                },
                success: function (response) {
                    Swal.fire(
                        '¡Gracias!',
                        'Los datos fueron enviados. Te contactarémos en breve.',
                        'success'
                    )
                }
            });

        }

    }

    /**
     * Show car disclaimer
     */
    this.showDisclaimer = function () {

        var elmt = $(this).find('span.disclaimer-up');

        if(elmt.length == 0) {
            return false;
        }

        var disclaimerID = $(elmt).data('id');
        var disclaimerTxt = $('.disclaimer-pop-' + disclaimerID).html();
        var html = '';

        html += '<div class="disclaimer-wrap">';
        html += '<span class="close-disclaimer"></span>';
        html += disclaimerTxt;
        html += '</div>';

        $('main').append(html);

        setTimeout(function (){
            $('.disclaimer-wrap').attr('style', 'bottom:0;opacity:1;');
        },300);

    };

    /**
     * Remove disclaimer
     */
    this.removeDisclaimer = function () {

        $('main .disclaimer-wrap').remove();

    };

})();

//Sticky menu on top when scrolling
let wrapper = $('#content'),
    vehicleNav = $('.VehicleOwnMenu');
let sticky = function () {
    let $scrollTop = $(window).scrollTop();
    if (vehicleNav.length > 0) {
        if ($scrollTop > 30) {
            wrapper.addClass('sticky');
        } else {
            wrapper.removeClass('sticky');
        }
    }

    Car.removeDisclaimer();
}
$(window).on('scroll', sticky);

$(window).resize(function () {
    Colors.removeHeight();
});
