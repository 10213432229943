/**
 * Efecto tipo acordeon
 * jerrydomm
 /* arrow, circle-arrow, plus(+) 
    nameClassToStyle to overwrite css
    markup:
    <ul class="collapseList nameClassToStyle">
        <li>
            <span class="nameClassToStyle">
                <em>{{TITLE}}</em>
            </span>
            <div class="dataCollapse">
                {{CONTENT}}
            </div>
        </li>
    </ul>
 */
jQuery.fn.extend({
    collapse: function (options) {
        defaults = {
            parent: '.collapseList',
            content: '.dataCollapse',
            indicator: "arrow",
            mobile: false,
            maxBreackpoint: null
        }

        var options = $.extend({}, defaults, options);

        let getCanvas = function () {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }

        if (!("ontouchstart" in document.documentElement)) {
            document.documentElement.className += " no-touch";
        }
        let windowsCheck = getCanvas();

        let _self = $(this);

        function symbol(e, self, rm) {
            if (rm) {
                if (e == "arrow") {
                    self.find('em,i').addClass('arrow');
                } else if (e == "circle-arrow") {
                    self.find('em,i').addClass('circle-arrow');
                } else if (e == "plus") {
                    self.find('em,i').addClass('plus');
                }
            } else {
                self.find('em,i').removeClass('arrow', 'circle-arrow', 'plus');
            }
        }


        if (options.mobile) {

            let mql = window.matchMedia("(max-width:" + options.maxBreackpoint + "px)");

            // call listener function explicitly at run time
            mediaqueryresponse(mql)
            // attach listener function to listen in on state changes
            mql.addListener(mediaqueryresponse)

            function mediaqueryresponse(mql) {
                if (mql.matches) {
                    symbol(options.indicator, _self, true);
                    $(options.parent).children().addClass('collapseThis');
                    $(options.parent).find(options.content).addClass('toCollapse');
                    $('.toCollapse').hide();
                    /* _self.each(function () {
                         $(this).off().on('click', openclose);
                     });*/
                    $(options.parent).each(function () {
                        $(this).find(_self).off().on('click', openclose);
                    });
                } else {
                    $(options.parent).children().removeClass('collapseThis');
                    $(options.parent).find('.toCollapse').removeAttr('style');
                    $(options.parent).find(options.content).removeClass('toCollapse');
                    $(options.parent).find(options.content).removeAttr('style');
                    symbol(options.indicator, _self, false);
                }
            }
        } else {
            console.log('no match');
        }

        function openclose() {
            var checkElement = $(this).next();

            $(options.parent + ' .collapseThis').removeClass('active');
            $(this).closest('.collapseThis').addClass('active');

            if ((checkElement.is('.toCollapse')) && (checkElement.is(':visible'))) {
                $(this).closest('.collapseThis').removeClass('active');
                checkElement.slideUp();
            }
            if ((checkElement.is('.toCollapse')) && (!checkElement.is(':visible'))) {
                $('.toCollapse' + ':visible').slideUp();
                checkElement.slideDown();
            }

            if (!checkElement.is('.toCollapse')) {
                return false;
            }

        }
    }
});
