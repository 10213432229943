window.Home = new (function () {

    var that = this;

    let toCarHome = $('.OurVehicles-tabs.home .MenuVehicles_types.home.standard');
    let toCarHomeSuvs = $('.OurVehicles-tabs.home .MenuVehicles_types.home.suvs');
    var setOpt = {
        infinite: false,
        dots: false,
        arrows: false,
        centerMode: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 99999,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                    centerMode: false,
                    //initialSlide: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    infinite: false,
                    centerMode: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    infinite: false,
                }
            }
        ]
    }
    var setOptSuvs = {
        infinite: false,
        dots: false,
        arrows: false,
        centerMode: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 99999,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                    centerMode: true,
                    initialSlide: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    infinite: false,
                    centerMode: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    infinite: false,
                }
            }
        ]
    }

    /**
     * Init
     */
    this.init = function () {

        that.banners();

        that.showLightBox();

        that.scrollAndOpenTab();

        $('.OurVehicles-tabs.home').simpleTab({
            container: '.OurVehicles-bar',
            handler: '.OurVehicles-bar>a',
            content: '.MenuVehicles_main',
            attribute: 'rel',
            namespeace: 'oveh',
            effect: "class",
            caller: function () {
                toCarHomeSuvs.filter('.slick-initialized').slick('refresh');
                toCarHome.filter('.slick-initialized').slick('refresh');
            }
        });

        $('.UnderLine-fx.suvs').trigger('click');

        (function(window, document) {
            'use strict';
            var bgset = function(elem, opts) {
                if (!elem || !opts || !opts.bg) {
                    return;
                }
                elem.style.backgroundImage = 'url(' + opts.bg + ')';
            };

            document.addEventListener('lazybeforeunveil', function(e) {
                var bg = e.target.getAttribute('data-bg');
                if (bg) {
                    bgset(e.target, { bg: bg });
                }
            });
        })(window, document);

    };

    /**
     * Banners
     */
    this.banners = function () {

        $('.HomeBannerBox').slick({
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 5000,
            lazyLoad: 'ondemand',
            appendDots: $(".navDots")
        });

        try {
            $('.exploring-slider').slick({
                dots: false,
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                lazyLoad: 'ondemand',
                autoplaySpeed: 5000,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            infinite: false,
                        }
                    }
                ]
            });
        } catch (e) {

        }

    };

    /**
     * Show lightbox for country
     */
    this.showLightBox = function () {

        var mql = window.matchMedia("(min-width: 992px)");
        var buttonConfirm = '<a style="display:block; color:white; height:100%; width:100%;" href="/covid-19">' + learnMore + '</a>';
        if (cookieExist == '0' && showligthB) {

            if (currentCountry == 'republica-dominicana') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '45%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'trinidad-and-tobago') {
                var img = 'images/extras/' + currentCountry + '/loved-by-nature-mobile.jpeg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/loved-by-nature.jpeg';
                    widthImg = '75%';
                }

                /*
                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
                 */
            } else if (currentCountry == 'antigua') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'st-lucia') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'grand-cayman') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'guatemala') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }
            } else if (currentCountry == 'costa-rica') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'bahamas') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'haiti') {
                var img = 'images/extras/' + currentCountry + '/covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + currentCountry + '/covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            } else if (currentCountry == 'jamaica') {
                $('#covid-jamaica').trigger('click');

            } else if (currentCountry == 'nicaragua') {
                $('#covid-nicaragua').trigger('click');

            } else if (currentCountry == 'el-salvador') {
                $('#expertos-elsalvador').trigger('click');

            } else if (currentCountry == 'honduras') {


            } else {
                var img = 'images/extras/' + siteLang + '-covid19-mobile.jpg';
                var widthImg = '90%';

                if (mql.matches) {
                    img = 'images/extras/' + siteLang + '-covid19.jpg';
                    widthImg = '75%';
                }

                Swal.fire({
                    imageUrl: img,
                    showConfirmButton: true,
                    confirmButtonText: buttonConfirm,
                    showCloseButton: true,
                    width: widthImg,
                    padding: '0',
                    confirmButtonColor: 'red',
                });
            }

        }

        //Ajax parameters
        var settings = {
            url: cookieRoute,
            method: "GET",
        }

        //ajax call
        $.ajax(settings).done(function (response) {
            console.log(response);
        }).fail(function (xhr, status, error) {
            console.log('error in create cookie');
        });


    };

    this.checkStoreLocator = function () {
        setTimeout(() => {
            if ($('.Service-locator').length <= 1) {
                $('.Service-locator').trigger('click');
            }
        }, 1500);
    };

    /**
     *
     */
    this.scrollAndOpenTab = function () {

        if (parameterForTabs != '') {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#vehicles-title").offset().top - 30
            }, 1000, function () {
                $('.OurVehicles-bar a.' + parameterForTabs).trigger('click');
            });
        }

    };

    function checkMobile() {

        let windowsCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;


        /*
        if (windowsCheck > 1025) {
            toCarHome.each((index, elem) => {
                if ($(elem).hasClass('slick-initialized')) {
                    $(elem).filter('.slick-initialized').slick('unslick');
                }
            });
            return;
        }
        */

        if (!toCarHome.hasClass('slick-initialized')) {

            toCarHome.each((index, elem) => {
                $(elem).find('.Vehicles-type').remove();
                $(elem).slick(setOpt);
            });

        }

        if (!toCarHomeSuvs.hasClass('slick-initialized')) {

            var finalSettings = setOptSuvs;

            if($('.OurVehicles-tabs.home .MenuVehicles_types.home.suvs .VehicleItem').length <= 3) {
                finalSettings = setOpt;
            }

            toCarHomeSuvs.each((index, elem) => {
                $(elem).find('.Vehicles-type').remove();
                $(elem).slick(finalSettings);
            });

        }

        return;
    }

    $(window).resize(checkMobile).trigger('resize');

})();
