/* 
 * revealme v0.1
 * autor: jerrydomm
 *  
 * Yep, funciona en móvil!
 *
 * usage:
 * <element data-class-in="awesomeAnimation" data-class-out="awesomeAnimation" data-offset-top="Number" class="animationtriger inview">
 * (<div data-class-in="fadeTopIn" class="fadeIn inview"></div>)
 *
 * 
 */


$(window).scroll(function () {

    var documentTop = $(window).scrollTop();
    var documentBottom = documentTop + $(window).innerHeight();
    var isDevice = (/android|webos|iphone|ipad|ipod|blackberry/i.test(navigator.userAgent.toLowerCase()));

    $('.inview').each(function (i) {

        var offsetTop = $(this).data('offset-top') || 0;

        if (!isDevice) {
            var blockTop = $(this).offset().top + offsetTop;

        } else {
            var blockTop = $(this).offset().top;
        }

        var blockHeight = $(this).innerHeight(),
            blockBottom = blockTop + blockHeight;


        if (!isDevice) {
            var viewRange = 0.2;
        } else {
            var viewRange = 0.1;
        }


        var top = Math.max(documentTop, blockTop);
        var bottom = Math.min(documentBottom, blockBottom);

        var height = bottom - top;


        if (height / blockHeight >= viewRange) {

            $(this).addClass($(this).data('class-in'));
            $(this).removeClass($(this).data('class-out'));


        } else if (height <= 0) {

            $(this).addClass($(this).data('class-out'));
            $(this).removeClass($(this).data('class-in'));

        }

    });

});
