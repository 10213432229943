window.Main = new (function () {

    var that = this;
    var showMsg = false;
    var theUserAnswer = '';

    this.init = function () {

        // $('header, .MenuVehicles_main').on('click', '.close-btn', that.closeCarMenu);

        $('a').on('click', that.checkForEmptyRoute);

        //Colapsables
        that.isMobileCollapse();

        //Init menu actions
        that.initMenuActions();

        //just numeric validation field
        $(document).on('keyup', '.justnumeric', that.justNumeric);

        //answer the survey
        $('.main-survey-wrap .score-wrap .score').on('click', that.answerTheSurvey);

        //save survey
        $('.main-survey-wrap .send-survey').on('click', that.saveTheSurvey);


        //close survey
        $('.main-survey-wrap .close-survey').on('click', function () {
            $('.main-survey-wrap').addClass('d-none');

            //Ajax parameters
            var settings = {
                url: 'close/quality-survey/',
                data: {
                    'closed' : 1,
                    'time' : $('#counter-usr').html()
                },
                method: "GET",
            }

            //ajax call
            $.ajax(settings).done(function (response) {
                console.log(response);
            }).fail(function (xhr, status, error) {
                console.log('error in create cookie');
            });

        });

        $('#counter-usr').countid({
            clock: true,
            dateTplElapsed: "%H:%M:%S",
        });

        $('#sendTeaserContactAction').on('click', that.sendTeaserContact);

        $(document).on('submit', 'form', that.checkSubmit);

        $('.notGo').on('click', function (e){
            e.preventDefault();

            let elementID = $(this).attr('href');

            $('html, body').animate({
                scrollTop: $(elementID).offset().top
            }, 2000);

        });

        that.runCountryServices();

        document.querySelectorAll('a[target="_blank"]').forEach(link => {
            link.setAttribute('rel', 'noopener');
        });
    };

    if (showMsg) {
        console.log("%cCOLMENA INTERACTIVE", "background:black; font-size: 20px; color: yellow; padding-left:10px; padding-right:10px;");
    }

    /**
     * Init menu actions
     */
    that.initMenuActions = function () {
        $('.collapseHandler').collapse({
            mobile: true,
            maxBreackpoint: 991,
            indicator: "plus"
        });

        $('.child').collapse({
            mobile: true,
            maxBreackpoint: 9999,
            parent: '.MenuOnMB .dropMb',
            content: '.dropMenus',
            indicator: "plus"
        });

        //headr vehicles menu

        //for desk
        $('.MenuTop .MenuVehicles').simpleTab({
            container: '.MenuVehicles-bar',
            handler: '.MenuVehicles-bar>a',
            content: '.MenuVehicles_main',
            attribute: 'rel',
            namespeace: 'veh',
            setContentheight: true
        });
        $('.MenuTop .CloneMenu').simpleTab({
            container: '.MenuVehicles-bar',
            handler: '.MenuVehicles-bar>a',
            content: '.MenuVehicles_main',
            attribute: 'rel',
            namespeace: 'cou',
            setContentheight: false
        });
        // for mobile
        $('.MenuMB .MenuVehicles').simpleTab({
            container: '.MenuVehicles-bar',
            handler: '.MenuVehicles-bar>a',
            content: '.MenuVehicles_main',
            attribute: 'rel',
            namespeace: 'mveh',
            setContentheight: true
        });
        $('.MenuMB .CloneMenu').simpleTab({
            container: '.MenuVehicles-bar',
            handler: '.MenuVehicles-bar>a',
            content: '.MenuVehicles_main',
            attribute: 'rel',
            namespeace: 'mcou',
            setContentheight: false
        });

        $('.OverlayMenu').on('click', function () {

            $(".Menus li").removeClass('current');
            $('.dropMenus').removeClass("expanded");
            if ($('.MenuOnMB').hasClass('current')) {
                $('.MenuOnMB').toggleClass('current');
            }
            $(this).toggleClass('outSide');

        });
        //Drops Menues

        that.HandlerMenu(".MenuTop .has-children", false, true);
        that.HandlerMenu(".MenuMB .has-child", true, true);

        //trigger click to suvs vhicles in menu & home
        $('.opt-suvs, .suvs').trigger('click');
    };

    /**
     * Trigger close menu
     */
    this.closeCarMenu = function (e) {
        if ($('.OpenCarPlease').is(':visible')) {
            $('.OpenCarPlease').trigger('click');
        } else {
            $('.Mb-vehicles').click();
        }
        return false;
    };

    /**
     * Check for empty Route in anchors
     */
    this.checkForEmptyRoute = function () {

        if ($(this).attr('href') == '') {
            return false;
        }
    };

    //general
    const getCanvas = function () {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    //menu collapse on mobile
    this.isMobileCollapse = function () {

        var mql = window.matchMedia("(min-width: 992px)")
        // call listener function explicitly at run time
        mediaqueryresponse(mql)
        // attach listener function to listen in on state changes
        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            // if media query matches
            if (mql.matches) {
                $('.MenuTop .child').addClass('has-children');
            } else {
                $('.MenuTop .child').removeClass('has-children');
            }
        }

    }

    //click outside menu to close
    this.overload = function () {
        $('.OverlayMenu').toggleClass('outSide');
    }

    //open / close drops menu
    this.HandlerMenu = function (handler, hamb = false, closemenu = false) {
        $(handler).each((index, elem) => {
            $(elem).on('click', function () {
                var checkElement = $(elem).next() || null;
                $(".Menus li").removeClass("current");
                $(elem).closest('li').addClass('current');

                if (hamb) {
                    function resetElem() {
                        $('.MenuOnMB').removeClass('openMenuTop');
                        $('#content').removeClass('noScroll');
                        $('.Hamburger').removeClass('openMenu');
                        $('.OverlayMenu').removeClass('outSide');
                    }
                    if ($('div.Hamburger', this).length > 0) {
                        var mql = window.matchMedia("(max-width: 992px)")
                        mediaqueryresponse(mql)
                        mql.addListener(mediaqueryresponse)

                        function mediaqueryresponse(mql) {
                            if (mql.matches) {
                                $('.Hamburger').toggleClass('openMenu');
                                $('.MenuOnMB').toggleClass('openMenuTop');
                                $('#content').toggleClass('noScroll');
                                $('.OverlayMenu').toggleClass('outSide');
                            } else {
                                resetElem();
                            }
                        }
                    } else {
                        resetElem();
                    }
                }

                if (checkElement.hasClass('expanded')) {
                    $(elem).closest('li').removeClass('current');
                    //$('.Menus .dropMenus').removeClass("expanded");
                    checkElement.removeClass("expanded");
                    $('.OverlayMenu').removeClass('outSide');
                }

                if (!checkElement.hasClass('expanded')) {
                    $('.Menus .dropMenus').removeClass("expanded");
                    checkElement.addClass("expanded");
                    $('.OverlayMenu').removeClass('outSide');
                    $('.OverlayMenu').addClass('outSide');
                }

                if (!$(elem).closest('li').hasClass('current')) {
                    $(this).next('.dropMenus').removeClass("expanded");
                    $('.OverlayMenu').removeClass('outSide');
                    return false;
                }

                return false;
            });

            if (closemenu) {
                $(elem).parent().on('click', '.close-btn', function () {
                    $(".Menus li").removeClass("current");
                    $('.Menus .dropMenus').removeClass("expanded");
                    $('.OverlayMenu').removeClass('outSide');
                });
            }

        });
    }
    /**
     * Validate correct email
     * @param emailAddress
     */
    this.isValidEmailAddress = function (emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };

    /**
     * Validate field, just numeric
     */
    this.justNumeric = function (e) {
        $(this).val($(this).val().replace(/[^0-9\.]/g, '').replace(/[\.\^\'\!]/g, ''));
    }; //justNumeric

    /**
     * Quality Survey
     */
    this.answerTheSurvey = function () {

        theUserAnswer = $(this).data('score');

        //show to user the selection
        $('.main-survey-wrap .score-wrap li').removeClass('selected');
        $(this).parent().addClass('selected');


    };//answerTheSurvey

    /**
     * Save Survey
     */
    this.saveTheSurvey = function () {

        if( theUserAnswer == '' ) {
            return;
        }
        //Ajax parameters
        var settings = {
            url: surveyCookieRoute,
            data: {
                'score' : theUserAnswer
            },
            method: "POST",
        }

        //ajax call (save data)
        $.ajax(settings).done(function (response) {
            $('.main-survey-wrap').addClass('d-none');
        }).fail(function (xhr, status, error) {
            console.log('error in create cookie');
        });


    };//saveTheSurvey

    this.sendTeaserContact = function () {

        $('#contactTeaserSend .error-fields').addClass('d-none');
        $('#contactTeaserSend #nameField').removeClass('error');
        $('#contactTeaserSend #emailField').removeClass('error');

        var theTeaserCar = $('#contactTeaserSend #teaserCarField').val();
        var theUserName = $('#contactTeaserSend #nameField').val();
        var theUserEmail = $('#contactTeaserSend #emailField').val();
        var theUserPhone = $('#contactTeaserSend #phoneField').val();
        var noError = true;

        if(theUserName == '') {
            $('#contactTeaserSend #nameField').addClass('error');
            noError = false;
        }
        if(theUserEmail == '') {
            $('#contactTeaserSend #emailField').addClass('error');
            noError = false;
        }
        if (!that.isValidEmailAddress(theUserEmail)) {
            $('#contactTeaserSend #emailField').addClass('error');
            noError = false;
        }

        //Ajax parameters
        var settings = {
            url: contactSendRoute,
            data: {
                'car' : theTeaserCar,
                'name' : theUserName,
                'email' : theUserEmail,
                'phone' : theUserPhone
            },
            method: "POST",
        }

        if( noError ) {
            //ajax call (save data)
            $.ajax(settings).done(function (response) {
                $('#contactTeaserSend .error-fields').removeClass('d-none');
                $('#contactTeaserSend .error-fields span').removeClass('badge-danger');
                $('#contactTeaserSend .error-fields span').addClass('badge-success');
                $('#contactTeaserSend .error-fields span').html(successSendText);

                //ga('send','event',theTeaserCar,'teaser','click-contact-form');
                try {
                    gtag('event', theTeaserCar, {
                        'event_category': 'teaser',
                        'event_label': 'click-contact-form'
                    });
                } catch (e) {
                    console.log(e);
                    console.log('Error send event GA4');
                }

            }).fail(function (xhr, status, error) {
                console.log('error in send teaser info');
            });
        } else {
            $('#contactTeaserSend .error-fields').removeClass('d-none');
        }

    };

    /**
     * Error Report
     */
    this.errorReport = function () {

        //Ajax parameters
        var settings = {
            url: errorReportRoute,
            data: {
                'error_url:' : currentURL,
            },
            method: "GET",
        }

        //ajax call (save data)
        $.ajax(settings).done(function (response) {
            console.log('error 500 sended');
        }).fail(function (xhr, status, error) {
            console.log('error in send 500');
        });

    };

    this.checkSubmit = function (e) {

        $(this).find(':submit').prop('disabled', true).addClass('disabled');

    };

    /**
     * Additional services for countries
     */
    this.runCountryServices = function () {
        if (currentCountry == 'costa-rica') {
            that.launchWhatsapp();
        }
        if (currentCountry == 'el-salvador') {
            that.launchWhatsapp();
        }
    };


    /*********************************************** Countries *******************************************************/
    this.launchWhatsapp = function () {
        //header
        let htmlWhatsapp = $('.SocialLinks #whatsapp-link');
        let htmlToAdd = '<a id="whatsapp-button" href="'+ $(htmlWhatsapp).attr('href') +'" target="_blank">' + $(htmlWhatsapp).html() + '</a>';

        $('.MenuTop .short-menu:first').append(htmlToAdd);
    };

})();






















