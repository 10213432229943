window.BuildCars = new (function () {

    const Swal = require('sweetalert2');
    const Colors = require('../vehicles/colors');

    var that = this;
    let windowsCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    this.init = function () {

        //build
        $('.BuildBoxed').on('init', function (event, slick) {
            let textNext = $('.slick-current').next().find('[data-step]').data('step');
            $('.slick-next').attr('aria-label', textNext);
            $('.slick-prev').attr('aria-label', '');
        }).on('afterChange', function (event, slick, currentSlide, nextSlide) {

            let textNext = $('.slick-current').next().find('[data-step]').data('step'),
                textPrev = $('.slick-current').prev().find('[data-step]').data('step');
            $('.slick-next').attr('aria-label', textNext);
            $('.slick-prev').attr('aria-label', textPrev);
            if (slick.$slides.length - 1 == currentSlide) {
                $('.slick-next').attr('aria-label', '');
            }
            if (currentSlide == 0) {
                $('.slick-prev').attr('aria-label', '');
            }

            $('.slick-next').attr('data-currentslide', currentSlide);
            $('.slick-prev').attr('data-currentslide', currentSlide);

            //when the slide is on the Color tab
            if (currentSlide == 3) {
                //validate if exist the Accessories Tab
                if ($('.BuildBoxed').find('.accessories-wrap').length == 0) {
                    $('.slick-next').addClass('show-btn').html('<span class="summary-get">' + summaryText + '</span>');
                    $('.summary-get').on('click', that.getSummary);
                }
            } else if (currentSlide == 4) {
                if ($('.BuildBoxed').find('.accessories-wrap').length != 0) {
                    $('.slick-next').addClass('show-btn').html('<span class="summary-get">' + summaryText + '</span>');
                    $('.summary-get').on('click', that.getSummary);
                }
            } else {
                if ($('.slick-next').hasClass('show-btn')) {
                    $('.slick-next.show-btn').html('<span class="btnInner">' + nextText + '</span>');
                    $('.slick-next').removeClass('show-btn');
                }
            }

        }).slick({
            dots: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            fade: true,
            customPaging: function (slider, i) {
                return '<button class="tab item"><em>' + (i + 1) + '</em><span>' + $(slider.$slides[i]).find('[data-step]').data('step') + '</span></button>';
            },
            appendDots: '.BuildSteps',
            nextArrow: '<button type="button" class="slick-next" aria-label=""><span class="btnInner">' + nextText + '</span></button>',
            prevArrow: '<button type="button" class="slick-prev" aria-label=""><span class="btnInner">' + prevText + '</span></button>',
        });

        if (selectedCar['model'] != '' && (selectedCar['model'] != 'no-car' || selectedCar['model'] != 'no-auto')) {
            $('.BuildBoxed').slick('slickGoTo', 1);
        }

        //init slick for versions (slide 2)
        that.initSlickForVersions();


        $('.BuildBoxed').slick('slickSetOption', 'swipe', false, false);


        //select the item chosen

        let carItem = $('.BuildBoxed .slick-slide');

        carItem.each((index, elem) => {
            $(elem).data('slick-index', index).on('click', '.SltcItem', function () {
                $(elem).data('slick-index', index).find('.SltcItem').removeClass('active');
                $(this).addClass('active');
            });
        });

        //Validate current slide selection
        //$(document).on('click', '.slick-next', that.validateCurrentSlide);
        $('.BuildBoxed').on('afterChange', function (event, slick, currentSlide, nextSlide) {

            that.validateCurrentSlide(event, slick, currentSlide, nextSlide);

        });

        if (selectedCar['category'] != '') {
            $('.Build-box .OurVehicles-bar .' + selectedCar['category']).trigger('click');
        }

        /**
         * Step One
         */

        //change pre selected car variable
        $('.Build-box .VehicleItem-box').on('click', that.stepOneSelectedModel);

        /**
         * Step Two
         */

        //get the selected version
        //$('.CarVersion-this .SltcItem').on('click', that.stepTwoSelectedVersion);
        $(document).on('click', '.CarVersion-this .SltcItem', that.stepTwoSelectedVersion);
        $('.CarVersion-this .SltcItem').first().trigger('click');

        /**
         * Step Three
         */

        //get the selected color
        $(document).on('click', '.exteriorColorItems .exteriorColorWrap', that.stepThreeSelectedVersion);
        $(document).on('click', '.colorInteriorWrap .colorInt', that.stepThreeSelectedInteriorColor);
        selectedCar['color'] = $('.exteriorColorItems .exteriorColorWrap.active').data('extname');

        //Interior color listener
        $(document).on('click', '.colorInteriorWrap .wrap-int-color .colorInt', that.getInteriorColorName);

        //update Html
        that.updateHtmlElements();

        //Close modal gallery
        $(document).on('click', '.close-btn', that.closeModal);

        //Go to summary
        $('.get-summary').on('click', that.getSummary);

        //Check for accessories
        that.getTheNewAccessoriesInfo();

        //Detect selected accessories
        $(document).on('click', '.item-container', that.selectAccessories);

        //PreSelect Version/Trim
        if (trimPreSelected != '') {
            $('.CarVersion-this .SltcItem.' + trimPreSelected).trigger('click');
        }

        //Listener powertrain
        $(document).on('click', '.selected-powertrain', that.selectedPowertrain);

        $('.description').on('click', Car.showDisclaimer);
        $(document).on('click', '.close-disclaimer', Car.removeDisclaimer);
    };

    /**
     * Close modal
     */
    this.closeModal = function () {
        $(document).find('.modal-gallery').removeClass('show');
        $(document).find('.modal-gallery').hide();
        $(document).find('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
    };

    /**
     * Package
     */
    this.buildPackage = function () {
        //package
        $('.packageCheck').on('click', function () {
            $(this).toggleClass('active');

            that.addRemovePackage($(this));
        });
    };

    /**
     * Add/Remove packages
     */
    this.addRemovePackage = function (packageSelected) {
        var price = $(packageSelected).data('package');

        if ($(packageSelected).hasClass('active')) {
            totalPrice = parseFloat(totalPrice) + parseFloat(price);
        } else {
            totalPrice = parseFloat(totalPrice) - parseFloat(price);
        }

        if (isNaN(totalPrice) || totalPrice == 0) {
            totalPrice = 0;
            $('.Build-price_digit span').addClass('price-not-available');
        } else {
            $('.Build-price_digit span').removeClass('price-not-available');
        }

        if (coinSymbol == '') {
            coinSymbol = '$';
        }

        $('.Sumary-total_price').html(coinSymbol + that.formatNumber(totalPrice));
        $('.Build-price_digit span').html((totalPrice == 0) ? notAvailable : coinSymbol + that.formatNumber(totalPrice));
    };

    /**
     * Validate current slide selection
     */
    this.validateCurrentSlide = function (event, slick, currentSlide, nextSlide) {

        if (selectedCar['name'] == '') {
            setTimeout(function () {
                $('.BuildBoxed').slick('slickGoTo', 0);
            }, 2000);
        }

        if (currentSlide == 3) {
            //$('.new-arrow .get-summary').removeClass('d-none');
            //$('.slick-next').addClass('d-none');
        } else {
            //$('.new-arrow .get-summary').addClass('d-none');
            //$('.slick-next').removeClass('d-none');
        }

    };

    /**
     * Change pre selected car variable
     */
    this.stepOneSelectedModel = function () {
        selectedCar['name'] = $(this).data('carname');
        selectedCar['model'] = $(this).data('carmodel');
        selectedCar['region'] = $(this).data('carregion');
        selectedCar['keyword'] = $(this).data('carkeyword');
        selectedCar['price'] = $(this).data('price');
        selectedCar['category'] = $(this).data('category');
        selectedCar['version_image'] = '';

        //get the car selected new info
        that.carSelectedNewInfo();

        //Reset slicks steps two, three & Four
        that.restartStepTwo();
        that.restartStepThree();
        that.restartStepFour();

        //update elements
        that.updateHtmlElements();

        //Search for accessories
        that.getTheNewAccessoriesInfo();
    };

    /**
     * get the selected version
     */
    this.stepTwoSelectedVersion = function () {
        selectedCar['version_id'] = $(this).data('versionid');
        selectedCar['version'] = $(this).data('version');
        selectedCar['price'] = (parseFloat($(this).data('price')) > 0) ? $(this).data('price') : selectedCar['price'];
        selectedCar['price_text'] = ($(this).data('pricetext') !== null && $(this).data('pricetext') !== '') ? $(this).data('pricetext') : $(this).data('coinsymbol');

        if (selectedCar['price_text'] == 0) {
            selectedCar['price_text'] = '';
        }
        selectedCar['version_image'] = $(this).data('versionimg');

        if (selectedCar['name'] == 'City' && selectedCar['model'] == '2021' && selectedCar['version'] != 'EX-L' && selectedCar['version'] != 'RS Hybrid') {
            $('.city-exl-show').removeClass('d-none');
        } else {
            $('.city-exl-show').addClass('d-none');
        }

        //update elements
        that.updateHtmlElements();

        //get the info for version selected colors
        that.getTheNewColorInfo();

        //get the powertrain
        that.getPowertrain();

    };

    /**
     * get the selected color
     */
    this.stepThreeSelectedVersion = function () {
        selectedCar['color'] = $(this).data('extname');
        selectedCar['360_image'] = $(this).data('folder') + '1.png';

        var firstInteriorColor = $('.colorInteriorWrap .wrap-int-color').find('.colorInt').first();
        $(firstInteriorColor).trigger('click');
        selectedCar['color_interior'] = $.trim($(firstInteriorColor).data('name'));


        //update src for accessory car
        $('.accessory-car').attr('src', selectedCar['360_image']);

        //update elements
        that.updateHtmlElements();

    };

    /**
     * get the selected interior color
     */
    this.stepThreeSelectedInteriorColor = function () {

        $('.colorInteriorWrap .colorInt').removeClass('selected');
        $(this).addClass('selected');

    };

    /**
     * get the info for version selected color
     */
    this.getTheNewColorInfo = function () {
        $.ajax({
            type: 'POST',
            url: getTheNewInfoVersionColorRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'car_name': selectedCar['keyword'],
                'car_model': selectedCar['model'],
                'car_region': selectedCar['region'],
                'version_id': selectedCar['version_id'],
            },
            success: function (response) {

                that.buildWrapColors360(response);

            }
        });
    };

    /**
     * Build wrap for colors 360
     */
    this.buildWrapColors360 = function (colors) {

        //Reset html
        that.restartStepThree();

        var carNameFor360 = selectedCar['name'] + ' ' + selectedCar['version'];

        if (selectedCar['name'] == 'Civic Si Sedan') {
            carNameFor360 = 'Civic Si ' + selectedCar['version'];
        }

        $('.CarVersion-sub span').html(carNameFor360);

        if (colors.length > 0) {

            var colorWrapContent = $('.CarVersion-color .exteriorColorItems');
            var colorContent = '';
            var active = '';

            $.each(colors, function (index, value) {

                if (index == 0) {
                    active = 'active';
                    imgFolder = assetExternal + value.folder;
                }

                var colorNameInterior = '';
                var colorHexaInterior = '';
                //$.each(value.interiors, function (indexInt, valueInt) {
                $.each(value.colors_int, function (indexInt, valueInt) {

                    //Get the hexadecimal color number
                    colorHexaInterior += valueInt.hexadecimal;

                    //Get color interior name
                    if (siteLang == 'es') {
                        colorNameInterior += valueInt.name_es;
                    } else {
                        colorNameInterior += valueInt.name_en;
                    }

                    //add comma if not is the las item
                    if (indexInt < (value.interiors.length - 1)) {
                        colorNameInterior += ',';
                        colorHexaInterior += ',';
                    }

                });

                var itemSmall = '';
                if (colors.length > 8) {
                    itemSmall = 'small';
                }

                colorContent += '<div class="item ' + itemSmall + '">';
                colorContent += '<div class="exteriorColorWrap ' + active + ' ' + itemSmall + '" ';
                colorContent += 'data-version="' + value.version + '" ';
                colorContent += 'data-extname="' + value.name + '" ';
                colorContent += 'data-intname=" ' + colorNameInterior + '" ';
                colorContent += 'data-inthexa="' + colorHexaInterior + '" ';
                colorContent += 'data-folder="' + assetExternal + value.folder + '">';
                colorContent += '<div class="exteriorColor" style="background-color: #' + value.hexadecimal + '"></div>';
                colorContent += '</div>';
                colorContent += '</div>';

                active = '';
            });

            //add html to the wrap
            $(colorWrapContent).append(colorContent);

            //Hide generic image
            $('.CarColor .generic-car').addClass('d-none');
            $('.threesixty').removeClass('d-none');
            $('.colorInteriorWrap').removeClass('d-none');
            //Init color items listeners
            Colors.init();
            //Init 306
            Colors.initExteriorCarousel();
            Colors.initView360();

            //Trigger click to first color
            $('.exteriorColorItems .exteriorColorWrap').find('.exteriorColor').first().trigger('click');
            //Trigger click to first interior color
            var firstInteriorColor = $('.colorInteriorWrap .wrap-int-color').find('.colorInt').first();
            $(firstInteriorColor).trigger('click');
            selectedCar['color_interior'] = $.trim($(firstInteriorColor).data('name'));
        }

    };

    /**
     * Get the interior color name selected
     */
    this.getInteriorColorName = function () {
        selectedCar['color_interior'] = $.trim($(this).data('name'));
    };

    /**
     * Update html elements
     */
    this.updateHtmlElements = function () {

        //---- Car name head
        var headTitle = ' ' + selectedCar['name'] + ' ';
        var spanTitle = $('<span></span>').html(selectedCar['model_to_show']);

        if (selectedCar['name'] == 'Civic Si Sedan') {
            headTitle = ' Civic Si ' + selectedCar['version'] + ' ';
        }

        //empty h2
        $('.BuildPage h2').html('');
        $('.head-car-model').html('');

        //update h2
        if (siteLang == 'en') {
            $('.BuildPage h2').append(spanTitle, headTitle);
            $('.head-car-model').html('<span>' + selectedCar['model_to_show'] + '</span>' + headTitle);
        } else {
            $('.BuildPage h2').append(headTitle, spanTitle);
            $('.head-car-model').html(headTitle + ' <span> ' + selectedCar['model_to_show'] + '</span>');
        }

        //---- Car price head
        var totalPrice = parseFloat(selectedCar['price']) + parseFloat(selectedCar['color_price']) + parseFloat(selectedCar['accessories_prices_resume']);

        if (isNaN(totalPrice) || totalPrice == 0) {
            totalPrice = 0;
            $('.Build-price_digit span').addClass('price-not-available');
        } else {
            $('.Build-price_digit span').removeClass('price-not-available');
        }


        //var priceText = '<small>' + selectedCar['price_text'].toUpperCase() + '</small>';
        var priceText = (selectedCar['price_text'] !== null) ? '<small>' + selectedCar['price_text'].toUpperCase() + '</small>' : '';
        //console.log('variable: '+selectedCar['price_text']);
        //console.log('texto: '+priceText);
        //console.log('coin: '+coinSymbol);
        //console.log(priceText, coinSymbol, selectedCar['price_text'], priceText.indexOf(coinSymbol));

        if( priceText != '' && priceText.indexOf(coinSymbol) ) {
            coinSymbol = '';
        }

        $('.Build-price_digit span').html((totalPrice == 0) ? notAvailable : priceText + coinSymbol + that.formatNumber(totalPrice));

        selectedCar['total_price'] = totalPrice;
        //selectedCar['coin_symbol'] = coinSymbol;


        //---- Car version image
        var versionImg = (selectedCar['version_image'] == '') ? carImageVersion : selectedCar['version_image'];
        $('#carForVersion').attr('src', versionImg);
        $('#carForVersionPowertrain').attr('src', versionImg);
        $('#carForVersion').attr('alt', selectedCar['model'] + ' ' + selectedCar['name']);
    }

    /**
     * get the car selected new info
     */
    this.carSelectedNewInfo = function () {

        $.ajax({
            type: 'POST',
            url: getTheNewInfoRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'car_name': selectedCar['keyword'],
                'car_model': selectedCar['model'],
                'car_region': selectedCar['region'],
            },
            success: function (response) {

                //update car selected info
                selectedCar['name'] = response.name;
                selectedCar['model'] = response.model_year;
                selectedCar['model_to_show'] = response.model_year_updated;
                selectedCar['region'] = response.region;
                selectedCar['keyword'] = response.base_car_model;
                //selectedCar['price'] = 0
                selectedCar['powertrain'] = '';
                selectedCar['category'] = '';
                selectedCar['version_image'] = '';

                if (response.name == 'City' && response.model_year == '2021' && (response.name_version_selected != 'EX-L' && response.name_version_selected != 'RS Hybrid')) {
                    $('.city-exl-show').removeClass('d-none');
                } else {
                    $('.city-exl-show').addClass('d-none');
                }

                //update slide 2
                that.updateCarVersions(response, response.versions);
            }
        });

    };

    /**
     * Update car versions slide
     * @param versions
     */
    this.updateCarVersions = function (response, versions) {

        that.restartStepThree();

        if (versions.length > 0) {

            var versionsWrapContent = $('.CarVersion-select .CarVersion-slrd');
            var versionContent = '';
            var active = '';
            var firstEnter = true;

            $.each(versions, function (index, value) {

                if (value.show) {
                    active = 'active';

                    selectedCar['price'] = (value.price > 0) ? value.price : selectedCar['price'];
                    firstEnter = false;
                }

                if (value.show) {
                    versionContent += '<div class="col-12 CarVersion-this SlctThis">';
                    versionContent += '<div class="SltcItem ' + active + '" ';
                    versionContent += 'data-price="' + value.price + '" ';
                    versionContent += 'data-coinsymbol="' + value.coin_symbol + '" ';
                    versionContent += 'data-pricetext="' + value.price_2wd_text + '" ';
                    versionContent += 'data-price4wd="' + value.price_4wd + '" ';
                    versionContent += 'data-pricetext4="' + value.price_4wd_text + '" ';
                    versionContent += 'data-versionimg=" ' + value.thumbnail + '" ';
                    versionContent += 'data-version="' + value.name + '" ';
                    versionContent += 'data-versionid="' + value.id + '">';
                    versionContent += '<div class="CarVersion-np">';
                    versionContent += '<span class="CarVersion-name">' + value.name + '</span><br>';

                    if (value.price != '0' && value.price != null) {
                        var localCoinSymbol = (value.coin_symbol !== null) ? value.coin_symbol : '';
                        var price2WDText = (typeof value.price_2wd_text != 'undefined' && value.price_2wd_text != null) ? value.price_2wd_text.toUpperCase() : '';
                        versionContent += '<span class="CarVersion-price"> ' + price2WDText + ' ' + localCoinSymbol + that.formatNumber(value.price) + '</span>';
                        coinSymbol = localCoinSymbol;
                    }

                    versionContent += '</div>';
                    versionContent += '<div class="CarVersion-data">';
                    if (index > 0) {
                        versionContent += '<label>';
                        versionContent += addsText;
                        versionContent += '</label>';
                    }
                    versionContent += '<p>';
                    versionContent += value.principal_features;
                    versionContent += '</p>';
                    versionContent += '</div>';
                    versionContent += '</div>';
                    versionContent += '</div>';
                }

                active = '';
            });


            $(versionsWrapContent).append(versionContent);

            //init slick for versions
            that.initSlickForVersions();

            //update powertrain
            that.updatePowertrain();
        }

        $('.CarVersion-this .SltcItem').first().trigger("click");

    };

    /**
     * Init slick for versions
     */
    this.initSlickForVersions = function () {

        let carSldr = $('.CarVersion-slrd');

        //verify if versions exist
        var versionsExistElements = $('.CarVersion-slrd .CarVersion-this');

        var showDots = false;

        if (windowsCheck > 992 && versionsExistElements.length > 3) {
            showDots = true;
        }

        let setOpts = {
            dots: showDots,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            mobileFirst: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                },
            }],
        }

        //Create slick
        if (versionsExistElements.length > 0) {
            carSldr.slick(setOpts);
            carSldr.slick('refresh');
        }


    };

    /**
     * Restart step two (versions)
     */
    this.restartStepTwo = function () {
        //remove slick
        try {
            $('.CarVersion-slrd').slick('unslick');
        } catch (e) {
            console.log(e);
        }

        $('.CarVersion-select .CarVersion-slrd').empty();
    };

    /**
     * Restart step three (colors)
     */
    this.restartStepThree = function () {
        //remove slick
        try {
            $('.exteriorColorItems').slick('unslick');
        } catch (e) {
            console.log(e);
        }

        //Empty wrap colors
        $('.CarVersion-color .exteriorColorItems').empty();
        //Empty label with name ext - int
        $('.colorTextsWrap .exteriorName').html('');
        $('.colorTextsWrap .interiorName').html('');

        //Delete the threesixty
        $('.threesixty').addClass('d-none');
        $('.colorInteriorWrap').addClass('d-none');
        $('.threesixty .threesixty_images').empty();

        //show generic image
        $('.CarColor .generic-car').removeClass('d-none');
    };

    /**
     * Restart step four (accessories)
     */
    this.restartStepFour = function () {
        //$('.modal-gallery ').remove();
        //Empty dynamic accessories alements
        $('.gallery-accessories').html('');
        //show generic image
        $('.CarAccessories .generic-car, .noAccessories .generic-car').attr('src', '/images/cars/colors/generic-car.png');
    };

    /**
     * get the info for accessories
     */
    this.getTheNewAccessoriesInfo = function () {

        $.ajax({
            type: 'POST',
            url: getTheNewInfoAccessoriesRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'car_name': selectedCar['keyword'],
                'car_model': selectedCar['model'],
                'car_region': selectedCar['region'],
            },
            success: function (response) {
                that.buildWrapAccessories(response);
            }
        });
    };

    /**
     * detect selected accessory
     */
    this.selectAccessories = function () {

        var item = $(this).find('.item');
        var description = $(item).next();
        var price = $(description).find('.prices').text()
        price = price.replace('$', '');

        if ($(this).find('.item').hasClass('selected')) {

            selectedCar['accessories'].push($(description).find('.description').text());
            selectedCar['accessories_prices'].push(price);
            //selectedCar['accessories_price'][$(this).attr('id')] = [parseInt(price)];

            selectedCar['accessories_prices_resume'] = selectedCar['accessories_prices_resume'] + parseInt(price);


        } else {

            selectedCar['accessories'] = $.grep(selectedCar['accessories'], function (value) {
                return value != $(description).find('.description').text()
            });
            selectedCar['accessories_prices'] = $.grep(selectedCar['accessories_prices'], function (value) {
                return value != price
            });

            selectedCar['accessories_prices_resume'] = selectedCar['accessories_prices_resume'] - parseInt(price);

        }

        if (isNaN(selectedCar['accessories_prices_resume'])) {
            selectedCar['accessories_prices_resume'] = 0;
        }

        that.updateHtmlElements();
    };

    /**
     * Check for accessories
     * @param accessories
     */
    this.buildWrapAccessories = function (accessories) {

        that.restartStepFour();

        // we unfilter all the tabs.
        $('.BuildBoxed').slick('slickUnfilter');

        if (accessories.length == 0 || (accessories.interior.length == 0 && accessories.exterior.length == 0 && accessories.electronics.length == 0)) {
            // we filter the accesories tab when is empty
            $('.BuildBoxed').slick('slickFilter', function (index, elem) {
                return $(elem).find('.accessories-wrap').length == 0;
            });
            $('.CarAccessories').addClass('d-none');
            $('.noAccessories').removeClass('d-none');
        } else {
            $('.noAccessories').addClass('d-none');
            $('.CarAccessories').removeClass('d-none');

            var accessoryWrap = $('.gallery-accessories');
            var accessoryContent = '';

            //interior
            var noPaddingInterior = (accessories.interior.length < 3) ? 'no-padding-xs' : '';
            accessoryContent += '<div class="slider interior ' + noPaddingInterior + '" data-category="interior" style="display:none">';
            $.each(accessories.interior, function (index, value) {
                accessoryContent += '<div>';
                accessoryContent += '   <div class="item-container" id="accessory_' + value.id + '">';
                accessoryContent += '       <div class="item" style="background-image: url(' + value.image + ')">';
                accessoryContent += '           <a href="" class="zoom-btn" data-index="' + index + '"><img src="/images/zoom.png"/></a>';
                accessoryContent += '       </div>';
                accessoryContent += '       <div class="item-description">';
                accessoryContent += '           <div class="description">' + value.description + '</div>';
                if (typeof value.price != 'undefined') {
                    accessoryContent += '           <div class="prices">' + value.price + '</div>';
                }
                accessoryContent += '       </div>';
                accessoryContent += '   </div>';
                accessoryContent += '</div>';
            });
            accessoryContent += '</div>';
            //end interior

            //exterior
            var noPaddingExterior = (accessories.exterior.length < 3) ? 'no-padding-xs' : '';
            accessoryContent += '<div class="slider exterior ' + noPaddingExterior + '" data-category="exterior" style="display:none">';
            $.each(accessories.exterior, function (index, value) {
                accessoryContent += '<div>';
                accessoryContent += '   <div class="item-container" id="accessory_' + value.id + '">';
                accessoryContent += '       <div class="item" style="background-image: url(' + value.image + ')">';
                accessoryContent += '           <a href="" class="zoom-btn" data-index="' + index + '"><img src="/images/zoom.png"/></a>';
                accessoryContent += '       </div>';
                accessoryContent += '       <div class="item-description">';
                accessoryContent += '           <div class="description">' + value.description + '</div>';
                if (typeof value.price != 'undefined') {
                    accessoryContent += '           <div class="prices">' + value.price + '</div>';
                }
                accessoryContent += '       </div>';
                accessoryContent += '   </div>';
                accessoryContent += '</div>';
            });
            accessoryContent += '</div>';
            //end exterior

            //electronic
            var noPaddingElectronic = (accessories.electronics.length < 3) ? 'no-padding-xs' : '';
            accessoryContent += '<div class="slider electronic ' + noPaddingElectronic + '" data-category="electronic" style="display:none">';
            $.each(accessories.electronic, function (index, value) {
                accessoryContent += '<div>';
                accessoryContent += '   <div class="item-container" id="accessory_' + value.id + '">';
                accessoryContent += '       <div class="item" style="background-image: url(' + value.image + ')">';
                accessoryContent += '           <a href="" class="zoom-btn" data-index="' + index + '"><img src="/images/zoom.png"/></a>';
                accessoryContent += '       </div>';
                accessoryContent += '       <div class="item-description">';
                accessoryContent += '           <div class="description">' + value.description + '</div>';
                if (typeof value.price != 'undefined') {
                    accessoryContent += '           <div class="prices">' + value.price + '</div>';
                }
                accessoryContent += '       </div>';
                accessoryContent += '   </div>';
                accessoryContent += '</div>';
            });
            accessoryContent += '</div>';
            //end electronic

            if (accessories.interior.length == 0) {
                $('.accessories-bar .int').addClass('d-none');
            }
            if (accessories.exterior.length == 0) {
                $('.accessories-bar .ext').addClass('d-none');
            }
            if (accessories.electronics.length == 0) {
                $('.accessories-bar .elec').addClass('d-none');
            }

            $(accessoryWrap).append(accessoryContent);
            AccessoriesGallery.init(accessories, {'addItem': 'ADD', 'removeItem': 'REMOVE'});
            var secondSectionAccessories = $('.accessories-bar').children().eq(0);
            $(secondSectionAccessories).trigger('click');

        }

    };

    /**
     * Get summary
     */
    this.getSummary = function () {
        var accessories = '';
        var accessories_prices = '';
        $.each(selectedCar['accessories'], function (index, value) {
            accessories += value + ', ';
        });
        $.each(selectedCar['accessories_prices'], function (index, value) {
            accessories_prices += value + ', ';
        });

        //send data for summary
        $.ajax({
            type: 'POST',
            url: prepareSummaryRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'car_name': selectedCar['name'],
                'car_powertrain': selectedCar['powertrain'],
                'car_powertrain_price': selectedCar['powertrain_price'],
                'car_model': selectedCar['model'],
                'car_model_updated': selectedCar['model_to_show'],
                'car_keyword': selectedCar['keyword'],
                'car_region': selectedCar['region'],
                'car_price': selectedCar['price'],
                'car_price_text': selectedCar['price_text'],
                'car_category': selectedCar['category'],
                'car_version_id': selectedCar['version_id'],
                'car_version': selectedCar['version'],
                'car_version_price': selectedCar['version_price'],
                'car_version_image': selectedCar['version_image'],
                'car_color': selectedCar['color'],
                'car_color_interior': selectedCar['color_interior'],
                'car_360_image': selectedCar['360_image'],
                'car_total_price': selectedCar['total_price'],
                'coin_symbol': selectedCar['coin_symbol'],
                'car_accessories': accessories,
                'car_accessories_prices': accessories_prices,
            },
            success: function (response) {

                var route = "/car/" + selectedCar['keyword'] + '/build-summary';

                if (siteLang == 'es') {
                    route = "/auto/" + selectedCar['keyword'] + '/armar-resumen';
                }

                window.location.href = route;

            }
        });

    };

    /**
     * Listener in Summary
     */
    this.listenerSummary = function () {
        //change car version price
        $('#trim').on('change', that.updateVersionPrice);

        //update Prices & Info
        that.updateVersionPrice();
    };

    /**
     * Buttons actions
     */
    this.buttonActions = function () {
        //delete accessories
        $('.delete-accessory').on('click', that.removeAccessorySummary);
        //send summary to adviser
        $('#sendToAdvisor').on('click', that.sendSummaryToAdvisor);
        //send me summary
        $('#sendMeSummary').on('click', that.sendMeSummary);

    };

    /**
     * Remove accessory Summary
     */
    this.removeAccessorySummary = function () {
        var accessoryIndex = $(this).data('index');
        var accessoryPrice = $(this).data('price');

        $('.accessory-' + accessoryIndex).remove();

        selectedCar['accessories'][accessoryIndex] = '';
        selectedCar['accessories_prices'][accessoryIndex] = '';

        totalPrice = totalPrice - parseFloat(accessoryPrice);

        if (isNaN(totalPrice) || totalPrice == 0) {
            totalPrice = 0;
            $('.Build-price_digit span').addClass('price-not-available');
        } else {
            $('.Build-price_digit span').removeClass('price-not-available');
        }

        if (coinSymbol == '') {
            coinSymbol = '$';
        }

        $('.Sumary-total_price').html(coinSymbol + that.formatNumber(totalPrice));
        $('.Build-price_digit span').html((totalPrice == 0) ? notAvailable : coinSymbol + that.formatNumber(totalPrice));

        if ($('.delete-accessory').length == 0) {
            var noMoreAccessories = '';
            noMoreAccessories += '<div class="row Sumary-elem_item no-gutters">';
            noMoreAccessories += '<div class="col-9 Sumary-elem_spec">' + noAccessoriesText + '</div>';
            noMoreAccessories += '<div class="col-3 Sumary-elem_price">--</div>';
            noMoreAccessories += '</div>';

            $('.wrap-accessories').append(noMoreAccessories);

        }

        //update info
        that.updateVersionPrice();
    };

    /**
     * Send summary to advisor
     */
    this.sendSummaryToAdvisor = function () {
        var name = $('#name').val();
        var phone = $('#phone').val();
        var email = $('#email').val();
        var dealership = $('#dealer_location').val();
        var apc_verification = '';

        try {
            apc_verification = $('#apc_verification').is(':checked');
        } catch (e) {

        }

        $('#name').removeClass('error');
        $('#email').removeClass('error');
        var validation = true;

        //validate name
        if ($.trim(name) == '') {
            $('#name').addClass('error');
            validation = false;
        }

        //validate email
        if (!Main.isValidEmailAddress(email)) {
            $('#email').addClass('error');
            validation = false;
        }

        if (!validation) {
            return false;
        }

        //update Prices & Info
        that.updateVersionPrice();


        Swal.fire({
            title: waitText,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //send data for summary
        $.ajax({
            type: 'POST',
            url: sendSummaryToAdvisorRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'name': name,
                'phone': phone,
                'email': email,
                'dealer_location': dealership,
                'apc_verification': apc_verification,
            },
            success: function (response) {

                Swal.fire({
                    title: sendAdvisorSuccessTitle,
                    text: sendAdvisorSuccessDescription,
                    type: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    //timer: 2500
                });


                console.log(selectedCar['name'], actionSendAdvisor, tagSendAdvisor);

                try {
                    gtag('event', actionSendAdvisor, {
                        'event_category': selectedCar['name'],
                        'event_label': tagSendAdvisor
                    });
                } catch (e) {
                    console.log(e);
                    console.log('Error send event GA4');
                }

            },
            error: function (xhr, status, error) {
                console.log(error);
                Swal.fire(haveProblemTitle, haveProblemDescription, 'warning');
            }
        });

    };

    /**
     * Send me summary
     */
    this.sendMeSummary = function () {
        var name = $('#name').val();
        var phone = $('#phone').val();
        var email = $('#email').val();
        $('#name').removeClass('error');
        $('#email').removeClass('error');
        $('#title-form').removeClass('error');
        var validation = true;

        //validate name
        if ($.trim(name) == '') {
            $('#name').addClass('error');
            validation = false;
        }

        //validate email
        if (!Main.isValidEmailAddress(email)) {
            $('#email').addClass('error');
            validation = false;
        }

        if (!validation) {
            $('#title-form').addClass('error');

            return false;
        }

        //update Prices & Info
        that.updateVersionPrice();

        Swal.fire({
            title: waitText,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //send data for summary
        $.ajax({
            type: 'POST',
            url: sendMeSummaryRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'name': name,
                'phone': phone,
                'email': email,
            },
            success: function (response) {

                var finalTitle = sendMeSuccessTitle;
                var finalText = sendMeSuccessDescription + ' ' + email;
                var finalTypeMessage = 'success';

                if (response != 'success') {
                    finalTitle = sendMeErrorTitle;
                    finalText = sendMeErrorDescription;
                    finalTypeMessage = 'error';
                }

                Swal.fire({
                    title: finalTitle,
                    text: finalText,
                    type: finalTypeMessage,
                    showCancelButton: false,
                    showConfirmButton: false,
                    //timer: 2500
                });

            },
            error: function (xhr, status, error) {
                Swal.fire(haveProblemTitle, haveProblemDescription, 'warning');
            }
        });

    };

    /**
     * Update version price
     */
    this.updateVersionPrice = function () {
        if (coinSymbol == '') {
            coinSymbol = '$';
        }
        var tPrice = totalPrice;
        var newCarPrice = parseFloat($('#trim option:selected').data('price'));
        var powertrain = $('#trim option:selected').data('powertrain');
        selectedCar['price'] = newCarPrice;
        selectedCar['version'] = $('#trim option:selected').val();

        /*
        if(selectedCar['powertrain'] == 'AWD') {
            selectedCar['price'] = newCarPriceAWD;
            newCarPrice = newCarPriceAWD;
        }
         */

        //remove car price before change
        totalPrice = totalPrice - parseFloat(priceBefore);
        //update the new car price
        totalPrice = totalPrice + parseFloat(newCarPrice);
        selectedCar['total_price'] = totalPrice

        //change priceBefore value
        priceBefore = parseFloat(newCarPrice);

        var priceText = (typeof selectedCar['price_text'] != 'undefined' && selectedCar['price_text'] !== null) ? '<small>' + selectedCar['price_text'].toUpperCase() + '</small>' : '';

        if(priceText.indexOf(coinSymbol)) {
            coinSymbol = '';
        }

        //Car Trim Price
        var carTrimPrice = priceText + coinSymbol + that.formatNumber(newCarPrice);

        if (isNaN(totalPrice) || totalPrice == 0) {
            totalPrice = 0;
            $('.Build-price_digit span').addClass('price-not-available');
        } else {
            $('.Build-price_digit span').removeClass('price-not-available');
        }

        if (isNaN(newCarPrice)) {
            carTrimPrice = '';
        }

        selectedCar['powertrain'] = powertrain;

        //update price
        $('.Sumary-total_price').html(priceText + coinSymbol + that.formatNumber(totalPrice));
        $('.Build-price_digit span').html((totalPrice == 0) ? notAvailable : priceText + coinSymbol + that.formatNumber(totalPrice));

        //update trim texts
        $('.trim-content').html($('#trim option:selected').val());
        $('.trim-content-powertrain').html(selectedCar['powertrain']);
        $('.trim-price-content').html(carTrimPrice);


        that.pushNewSummaryData();
    };

    /**
     * Send data for cache
     */
    this.pushNewSummaryData = function () {

        var accessories = '';
        var accessories_prices = '';
        $.each(selectedCar['accessories'], function (index, value) {
            var acc = $.trim(value);
            if (acc = !'') {
                accessories += value + ', ';
            }

        });
        $.each(selectedCar['accessories_prices'], function (index, value) {
            var acc = $.trim(value);
            if (acc = !'') {
                accessories_prices += value + ', ';
            }

        });

        try {
            //send data for summary
            $.ajax({
                type: 'POST',
                url: prepareSummaryRoute,
                data: {
                    '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                    'car_name': selectedCar['name'],
                    'car_model': selectedCar['model'],
                    'car_powertrain': selectedCar['powertrain'],
                    'car_powertrain_price': selectedCar['powertrain_price'],
                    'car_model_updated': selectedCar['model_to_show'],
                    'car_keyword': selectedCar['keyword'],
                    'car_region': selectedCar['region'],
                    'car_price': selectedCar['price'],
                    'car_price_text': selectedCar['price_text'],
                    'car_category': selectedCar['category'],
                    'car_version_id': selectedCar['version_id'],
                    'car_version': selectedCar['version'],
                    'car_version_price': selectedCar['version_price'],
                    'car_version_image': selectedCar['version_image'],
                    'car_color': selectedCar['color'],
                    'car_color_interior': selectedCar['color_interior'],
                    'car_360_image': selectedCar['360_image'],
                    'car_total_price': selectedCar['total_price'],
                    'coin_symbol': selectedCar['coin_symbol'],
                    'car_accessories': accessories,
                    'car_accessories_prices': accessories_prices,
                    'downPayment': downPayment,
                    'termMonths': termMonths,
                    'totalPriceMonthly': totalPriceMonthly.toFixed(2),
                },
                success: function (response) {

                }
            });
        } catch (e) {

        }
    };

    /**
     * Get the new data for powetrain
     */
    this.getPowertrain = function (versionID) {

        if (typeof getTheNewInfoRoute != 'undefined') {
            $.ajax({
                type: 'POST',
                url: getTheNewInfoRoute,
                data: {
                    '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                    'car_name': selectedCar['keyword'],
                    'car_model': selectedCar['model'],
                    'car_region': selectedCar['region'],
                    'version_id': selectedCar['version_id'],
                },
                success: function (response) {

                    //update car selected info
                    selectedCar['powertrain'] = response.powertrain;

                    //update slide 2
                    that.updatePowertrain();
                }
            });
        }

    };

    /**
     * Update Powertrain
     */
    this.updatePowertrain = function () {

        that.cleanPowertrain();

        var wrap = '';
        var numberOfColums = '';
        var firstPowertrain = '';
        var firstPowertrainPrice = '';
        if (selectedCar['powertrain'].length <= 1) {
            numberOfColums = 'offset-3';
        }

        var engineType = 'CVT';

        if (selectedCar['name'] == 'Odyssey') {
            engineType = 'TA10';

            if (siteLang == 'en') {
                engineType = '10AT';
            }
        }

        $.each(selectedCar['powertrain'], function (index, powertrain) {
            var text4wd = (typeof powertrain.price_4wd_text != 'undefined' && powertrain.price_4wd_text != null) ? powertrain.price_4wd_text.toUpperCase() : '';
            var price4wd = (typeof powertrain.price_4wd != 'undefined' && powertrain.price_4wd != null) ? that.formatNumber(powertrain.price_4wd) : '';

            wrap += '<div class="col-md-6 ' + numberOfColums + ' selected-powertrain" ' +
                'data-powertrain="' + powertrain.powertrain + '" ' +
                'data-price="' + powertrain.price + '"' +
                'data-pricetext="' + powertrain.price_2wd_text + '"' +
                'data-price4wd="' + powertrain.price_4wd + '"' +
                'data-price4wdtext="' + powertrain.price_4wd_text + '">';

            if (index == 0) {
                var selectedItem = 'active';
            }
            wrap += '<div class="SltcItem ' + selectedItem + '">';
            wrap += '<figure><img src="' + assetLocal + '/' + powertrain.powertrain.toLowerCase() + '.svg" alt="" class="img-fluid"></figure>';
            wrap += '<div class="CarPowerTrain-name">';
            wrap += '<span>' + powertrain.powertrain + ' ' + engineType + '</span>';

            if (index == 0) {
                wrap += '<small>(' + powertraIncludedText + ')</small>';
            } else {
                if(siteLang == 'en') {
                    wrap += '<small>' + price4wd + '<span class="small d-inline-block">' + text4wd + '</span></small>';
                } else {
                    wrap += '<small><span class="small d-inline-block">' + text4wd + '</span> ' + price4wd + '</small>';
                }
            }

            wrap += '</div>';
            wrap += '</div>';
            wrap += '</div>';

            if (index == 0) {
                firstPowertrain = powertrain.powertrain;
                firstPowertrainPrice = powertrain.price;
            }

        });

        $('.CarPowerTrain-item').append(wrap);

        $('.CarPowerTrain-item .SltcItem').first().trigger('click');

        selectedCar['powertrain'] = firstPowertrain;
        selectedCar['powertrain_price'] = firstPowertrainPrice;
    };

    /**
     * Select powetrain
     */
    this.selectedPowertrain = function () {
        var pwrtrain = $(this).data('powertrain');
        var pwr2wdPrice = $(this).data('price');
        //var coinsymbol = $(this).data('coinsymbol');
        var pwr2wdPriceText = $(this).data('pricetext');
        var pwr4wdPrice = $(this).data('price4wd');
        var pwr4wdPriceText = $(this).data('price4wdtext');

        if (pwrtrain == '2WD' || pwr4wdPrice == null) {
            selectedCar['price'] = pwr2wdPrice;
            selectedCar['powertrain_price'] = pwr2wdPrice;
            //selectedCar['price_text'] = pwr2wdPriceText;
            //selectedCar['coin_symbol'] = coinsymbol;
        } else {
            selectedCar['price'] = pwr4wdPrice;
            selectedCar['powertrain_price'] = pwr4wdPrice;
            //selectedCar['price_text'] = pwr4wdPriceText;
            //selectedCar['coin_symbol'] = coinsymbol;
        }
        selectedCar['powertrain'] = pwrtrain;


        that.updateHtmlElements();
    };

    /**
     * Clean Powertrain
     */
    this.cleanPowertrain = function () {

        //clear html
        $('.CarPowerTrain-item').html('');
    };


    //fixed width
    function isMobile() {

        if (windowsCheck > 990) {
            $('.Sumary-title').css('width', ($('.SumaryBox>.container').outerWidth() / 2) - 30);
        } else {
            $('.Sumary-title').css('width', 'inital');
        }

    }

    //format number
    this.formatNumber = function (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    };

    //remove format number
    this.notFormatNumber = function (num) {
        num = num + '';
        var theNumber = num.split('.');
        return theNumber[0].replace(',', '');
    };

    $(window).resize(isMobile).trigger('resize');

})();
