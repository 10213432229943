/*
*   Simple tabs
*   jerrydomm
*   
    markup:
    <ul class="jdtab">
        <li><a href="#tab1">TAB 1</a></li>
    </ul>
    <div class="namespace-container">
        <div class="jdtab_content" id="tab1">
            {{ contenido }}
        </div>
    </div>
    js:
    $('.jdtab li').simpleTab({
        content: '.content',
        attribute: 'href', (options:href o rel)
        effect: "fade"     (options:fade o show)
    });

*/

jQuery.fn.extend({
    simpleTab: function (options) {
        defaults = {
            namespeace: '',
            container: '.jdtabs',
            handler: '.jdtab>li',
            content: '.jdtab_content',
            attribute: 'href',
            effect: "fade",
            caller: function () {},
            setContentheight: false
        }
        var self = $(this);
        var options = $.extend({}, defaults, options);

        $(self, 'options.container').each(function (i) {
            var clicker = $(this).find(options.handler),
                content = $(this).find(options.content);

            if (options.attribute == "rel") {
                if (options.namespeace == '') {
                    console.group('Tabs')
                    console.warn('Pleace add a namespece when use rel attribute');
                    console.info('exp: namespace: "something"');
                    console.groupEnd();
                } else {
                    clicker.each(function (i) {
                        $(this).attr(options.attribute, '#' + options.namespeace + i++);
                    });
                    content.each(function (i) {
                        $(this).attr('id', options.namespeace + i++);
                    });
                }
            }
            if (options.effect == "class") {
                content.addClass('hideTab');
                content.first().removeClass('hideTab');
                content.first().addClass('showTab');
            } else {
                content.hide();
                content.first().show();
            }
            if (options.setContentheight) {
                content.first().parent().parent().css('height', $(window).height() - 170);
            }
            clicker.first().addClass("current");

            clicker.click(function (e) {
                e.preventDefault();
                clicker.removeClass("current");
                $(this).addClass("current");

                if (options.effect == "class") {
                    content.removeClass('showTab');
                    content.addClass('hideTab');
                } else {
                    content.hide();
                }

                if (options.attribute == "rel") {
                    var activeTab = $(this).attr(options.attribute);

                } else {
                    var activeTab = $(this).find('a').attr(options.attribute) || $(this).attr(options.attribute);

                }
                if (options.effect == "fade") {
                    $(activeTab).fadeIn();

                } else if (options.effect == "class") {
                    $(activeTab).removeClass('hideTab');
                    $(activeTab).addClass('showTab');
                } else {
                    $(activeTab).show();
                }
                //callback
                options.caller.call();
                //content.parent().parent().height($(activeTab).eq(0).height());

                //Heights
                if (options.setContentheight) {
                    if ($(activeTab).attr('id') === options.namespeace + "0") {
                        $(activeTab).parent().parent().css('height', $(window).height() - 170);
                    } else {
                        $(activeTab).parent().parent().css('height', 'auto');
                    }
                }
                return false;
            });
        })

    }
});
