/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
if (!isIE11) {
    import('lozad').then(({ default: lozad }) => {
        const observer = lozad();
        observer.observe();
    });
}

require('slick-carousel');
require('./front/collapse');
require('./front/jdTab');
require('./front/revealme');
require('./front/main');
require('./front/home');
require('./vehicles/car');
require('./front/buildCars');
//window.Vue = require('vue');

import VueCurrencyFilter from 'vue-currency-filter';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

require('./front/countid.min');
